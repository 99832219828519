const ru = {
    securityCheckTitle: 'Проверка безопасности',
    securityCheckDesc: 'Пожалуйста, подтвердите, что вы не робот, пройдя проверку ниже.',
    securityCheckSuccess: 'Успешно! Вы будете автоматически перенаправлены...',
    securityCheckError: 'Проверка не пройдена, попробуйте снова.',
    captchaStatusPassed: 'Если перенаправление не произошло, ',
    captchaStatusPassedClickHere: 'нажмите здесь',
    welcomeTitle: 'Добро пожаловать!',
    welcomeDesc: 'Ты прошел проверку и получил доступ к коммьюнити.',

    welcomePromoCode: '🎁 Активируй промокод WELCOME и ищи актуальный в закрепленном сообщении чата!',
    welcomeBtn: 'Перейти в чат',
    welcomeSupportText: 'Если у вас возникли вопросы, обратитесь в',
    welcomeSupport: 'службу поддержки'
    // Добавьте другие тексты
  };
  
  export default ru;
  