const en = {
    securityCheckTitle: 'Security Check',
    securityCheckDesc: 'Please confirm that you are not a robot by completing the check below.',
    securityCheckSuccess: 'Success! You will be automatically redirected...',
    securityCheckError: 'Verification failed, please try again.',
    captchaStatusPassed: 'If the redirection did not occur,',
    captchaStatusPassedClickHere: 'click here',
    welcomeTitle: 'Welcome!',
    "welcomeDesc": "You have passed the check and gained access to the community.",
    "welcomePromoCode": "🎁 Activate the promo code WELCOME and look for the latest one in the pinned message of the chat!",
    welcomeBtn: 'Go to chat',
    welcomeSupportText: 'If you have any questions, please contact',
    welcomeSupport: 'customer support'
    // Добавьте другие тексты
  };
  
  export default en;