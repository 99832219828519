import { useState, useEffect } from 'react';
import translations from '../language/translations';

const useLanguage = () => {
  const [texts, setTexts] = useState(translations.en); // По умолчанию английские тексты
  const [language, setLanguage] = useState('en'); // По умолчанию английский
  useEffect(() => {
    if (window.Telegram?.WebApp) {
      const { user } = window.Telegram.WebApp.initDataUnsafe || window.Telegram.WebApp.initData || {};
      const userLang = user?.language_code;

      if (userLang) {
        const lang = userLang === 'ru' ? 'ru' : 'en';
        setLanguage(lang);
        setTexts(translations[lang]);
      }
    }
  }, []);

  return texts;
};

export default useLanguage;