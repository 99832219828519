import React from 'react'
import Header from './components/Header'
import InfoContent from './components/InfoContent'
import {useTheme} from '../../shared/context/ThemeProvider'
import {useEffect, useState} from 'react'
const WelcomePage = () => {
  const { theme } = useTheme();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.close();
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <div>
          <Header/>
          <InfoContent theme={theme}/>
    </div>
  )
}

export default WelcomePage
