import './index.css'
import lockGray from '../../images/lockGray.svg'
import lockGreen from '../../images/lockGreen.svg'
import lockRed from '../../images/lockRed.svg'
import {useTheme} from '../../../../shared/context/ThemeProvider'

const Header = ({className, captchaPassed, captchaFailed}) => {
  const { theme } = useTheme();
  let imgSrc = lockGray;
    if (captchaPassed) imgSrc = lockGreen;
    if (captchaFailed) imgSrc = lockRed;
  return (
    <div className={`${className} ${theme}`}>
    <img className='img' src={imgSrc} alt="Lock Icon" />
    </div> 
  )
}

export default Header
