import React, { createContext, useContext, useState, useEffect } from 'react';

const ThemeContext = createContext();

export function ThemeProvider({ children }) {
  const [theme, setTheme] = useState('light'); // Установка начального значения темы

  useEffect(() => {
    // Проверка доступности Telegram WebApp API и получение параметров темы
    if (window.Telegram && window.Telegram.WebApp) {
      const isDark = window.Telegram.WebApp.themeParams.bg_color && parseInt(window.Telegram.WebApp.themeParams.bg_color.slice(1), 16) < 0x888888;
      setTheme(isDark ? 'dark' : 'light');
    }
  }, []);

  return (
    <ThemeContext.Provider value={{ theme }}>
      {children}
    </ThemeContext.Provider>
  );
}

// Хук для использования темы в компонентах
export const useTheme = () => useContext(ThemeContext);
