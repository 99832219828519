import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import WelcomePage from './pages/Welcome'
import SecurityCheck from './pages/SecurityCheck'
import {useEffect, useState} from 'react'
const RedirectToExternal = () => {
  useEffect(() => {
    window.location.href = 'https://tonroll.com';
  }, []);

  return null;
};
function App() {
  const [theme, setTheme] = useState('light'); // Значение по умолчанию

  useEffect(() => {
    // Проверка доступности Telegram WebApp API
    if (window.Telegram.WebApp) {
      // Получаем цвет фона из Telegram ThemeParams
      const bgColor = window.Telegram.WebApp.themeParams.bg_color;
      // Определение, является ли фон темным
      if (bgColor && isDark(bgColor)) {
        setTheme('dark');
      } else {
        setTheme('light');
      }
    }
  }, []);
  useEffect(() => {
    if (window.Telegram) {
      console.log("window.Telegram is available:", window.Telegram);
    } else {
      console.log("window.Telegram is not available");
    }
  }, []);

  // Функция для определения, является ли цвет темным
  function isDark(color) {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    // Простая проверка на яркость
    return (r * 0.299 + g * 0.587 + b * 0.114) < 186;
  }
  return (
    <Router>
      <div className={`App ${theme}`}>
        <Routes>
          <Route path="/" element={<RedirectToExternal />} />
          <Route path="/security" element={<SecurityCheck />} />
          <Route path="/welcome" element={<WelcomePage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
