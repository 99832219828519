import React from 'react'
import './index.css'
import useLanguage from '../../../../shared/hooks/useLanguage'
import { useParams } from 'react-router-dom';
const InfoContent = ({theme}) => {


  const texts = useLanguage();
  const handleChatRedirect = () => {
    const startParam = window.Telegram.WebApp.initDataUnsafe.start_param;
    const [cid, uid] = startParam.split('_');

    if (cid == '-1001525687467') {
      window.location.href = 'https://t.me/tonroll_web3';
    } else if (cid == '-1001925763706') {
      window.location.href = 'https://t.me/tonroll_family';
    } else if (cid == '-1001675251017') {
      window.location.href = 'https://t.me/tonroll_chat_zh';
    }else if (cid == '-1001765600842') {
      window.location.href = 'https://t.me/tonroll_chat_ae';
    }else if (cid == '-1001510084930') {
      window.location.href = 'https://t.me/tonroll_chat_tr';
    }else if (cid == '-1002018061934') {
      window.location.href = 'https://t.me/tonroll_chat_pt';
    }else if (cid == '-1001909963024') {
      window.location.href = 'https://t.me/tonroll_chat_fa';
    }

     // Вставить тестовую ссылку на чат
  }
  return (
    <div>
    <div className={'mainblock'}>
      <p className={'title'}>{texts.welcomeTitle}</p>
      <p className={'desc'}>{texts.welcomeDesc}</p>
      </div>
      <div style={{
        marginBottom: '32px'
      }}>
        <div className={`text_block ${theme}`}>{texts.welcomePromoCode}</div>
        <button className={'btn_chat'} onClick={handleChatRedirect}>{texts.welcomeBtn}</button>
      </div>
    </div>
  )
}

export default InfoContent
