import './index.css'
import { useTheme } from '../../../../shared/context/ThemeProvider'
import smile from '../../images/smile.svg'

const Header = () => {
  const { theme } = useTheme();
  return (
    <div className={`header_welcome ${theme}`}>
    <img className='img' src={smile} />
    </div>
  )
}

export default Header
