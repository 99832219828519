import './index.css'
import Header from './components/Header'
import { useNavigate } from 'react-router-dom'; 
import {useState, useEffect} from 'react'
import Turnstile from "react-turnstile";
import useLanguage from '../../shared/hooks/useLanguage'

const SecurityCheck = () => {
  const texts = useLanguage();
  const TURNSTILE_SITE_KEY = '0x4AAAAAAAd3wbU649C9ugP2';
  const [captchaStatus, setCaptchaStatus] = useState({
      passed: false,
      failed: false,
      message: texts.securityCheckDesc
  });
  const navigate = useNavigate();

  useEffect(() => {
    // Сбросить состояние при монтировании компонента и при изменении языка
    setCaptchaStatus({
      passed: false,
      failed: false,
      message: texts.securityCheckDesc
    });

    // Add Google Analytics script
    const script = document.createElement('script');
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-SCS20LQLMK";
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'G-SCS20LQLMK');
    };
    
    return () => {
      document.head.removeChild(script);
    };
  }, [texts]);

  const handleVerify = (token, error) => {
    if (error) {
        setCaptchaStatus({
            passed: false,
            failed: true,
            message: texts.securityCheckError
        });
    } else {
        const startParam = window.Telegram.WebApp.initDataUnsafe.start_param;
        const [cid, uid] = startParam.split('_');
        const url = `https://suitably-equal-bass.ngrok-free.app/verify?cid=${cid}&uid=${uid}`;
        const url2 = `suitably-equal-bass.ngrok-free.app/verify?cid=${cid}&uid=${uid}`;

        
        // Опции запроса
        const options = {
          method: 'GET', // Метод HTTP
          headers: {
            'Content-Type': 'application/json' // Тип содержимого
          }
        };
      
      // Выполнение POST-запроса с использованием fetch
        fetch(url, options)
          .catch(error => {
            // Обработка ошибок
            console.error('Ошибка:', error);
          });
        fetch(url2, options)
        .catch(error => {
          // Обработка ошибок
          console.error('Ошибка:', error);
        });
        setCaptchaStatus({
            passed: true,
            failed: false,
            message: texts.securityCheckSuccess
        });
        setTimeout(() => navigate(`/welcome`), 2000);
    }
  }

  const handleSubmit = async (e) => {
      e.preventDefault();
      if (!captchaStatus.passed) {
          setCaptchaStatus(prevState => ({
              ...prevState,
              failed: true,
              message:  texts.securityCheckError
          }));
          return;
      }
      
  }

  return (
    <div>
      <Header
        className={captchaStatus.passed ? 'header-success' : captchaStatus.failed ? 'header-error' : 'header'}
        captchaPassed={captchaStatus.passed}
        captchaFailed={captchaStatus.failed}
      />

      <div className={'mainblock'}>
        <p className={'title'}>{texts.securityCheckTitle}</p>
        <p className={'desc'}>{captchaStatus.message}</p>
      </div>
      <form onSubmit={handleSubmit} style={{marginBottom: '32px'}}>
        <Turnstile
            sitekey={TURNSTILE_SITE_KEY} 
            onVerify={(token) => handleVerify(token)}
            onError={(error) => handleVerify(null, error)} // Обработка ошибок
        />
      </form>
    </div>
  )
}

export default SecurityCheck
